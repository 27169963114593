*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #1c64f2;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  user-select: none;
  height: 1rem;
  width: 1rem;
  color: #1c64f2;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked, .dark [type="checkbox"]:checked, .dark [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px auto inherit;
}

input[type="file"]::file-selector-button {
  color: #fff;
  cursor: pointer;
  background: #1f2937;
  border: 0;
  margin-inline: -1rem 1rem;
  padding: .625rem 1rem .625rem 2rem;
  font-size: .875rem;
  font-weight: 500;
}

input[type="file"]::file-selector-button:hover {
  background: #374151;
}

input[type="range"]::-webkit-slider-thumb {
  height: 1.25rem;
  width: 1.25rem;
  appearance: none;
  cursor: pointer;
  background: #1c64f2;
  border: 0;
  border-radius: 9999px;
}

input[type="range"]:disabled::-webkit-slider-thumb {
  background: #9ca3af;
}

input[type="range"]:focus::-webkit-slider-thumb {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1px;
  --tw-ring-color: rgb(164 202 254 / var(--tw-ring-opacity));
  outline: 2px solid #0000;
}

input[type="range"]::-moz-range-thumb {
  height: 1.25rem;
  width: 1.25rem;
  appearance: none;
  cursor: pointer;
  background: #1c64f2;
  border: 0;
  border-radius: 9999px;
}

input[type="range"]:disabled::-moz-range-thumb {
  background: #9ca3af;
}

input[type="range"]::-moz-range-progress {
  background: #3f83f8;
}

input[type="range"]::-ms-fill-lower {
  background: #3f83f8;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3f83f880;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.-bottom-px {
  bottom: -1px;
}

.top-\[-102px\] {
  top: -102px;
}

.right-0 {
  right: 0;
}

.-bottom-11 {
  bottom: -2.75rem;
}

.-bottom-20 {
  bottom: -5rem;
}

.top-14 {
  top: 3.5rem;
}

.top-0 {
  top: 0;
}

.isolate {
  isolation: isolate;
}

.z-\[9000\] {
  z-index: 9000;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mb-36 {
  margin-bottom: 9rem;
}

.mr-3 {
  margin-right: .75rem;
}

.ml-3 {
  margin-left: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-44 {
  margin-bottom: 11rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-32 {
  margin-bottom: 8rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-\[-67px\] {
  margin-left: -67px;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-40 {
  margin-top: 10rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.h-6 {
  height: 1.5rem;
}

.h-16 {
  height: 4rem;
}

.h-10 {
  height: 2.5rem;
}

.h-8 {
  height: 2rem;
}

.h-5 {
  height: 1.25rem;
}

.min-h-\[125px\] {
  min-height: 125px;
}

.w-full {
  width: 100%;
}

.w-6 {
  width: 1.5rem;
}

.w-fit {
  width: fit-content;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-16 {
  width: 4rem;
}

.w-5 {
  width: 1.25rem;
}

.min-w-\[280\.5px\] {
  min-width: 280.5px;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-\[30ch\] {
  max-width: 30ch;
}

.max-w-\[15ch\] {
  max-width: 15ch;
}

.max-w-\[20ch\] {
  max-width: 20ch;
}

.max-w-\[60ch\] {
  max-width: 60ch;
}

.cursor-pointer {
  cursor: pointer;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-4 {
  gap: 1rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-8 {
  gap: 2rem;
}

.space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
}

.space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(4rem * var(--tw-space-y-reverse));
}

.space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(5rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)) );
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.border-\[\#A9BAE4\] {
  --tw-border-opacity: 1;
  border-color: rgb(169 186 228 / var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-slate-500 {
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(26 86 219 / var(--tw-bg-opacity));
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(12 60 186 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(3 4 94 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-slate-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-opacity-60 {
  --tw-bg-opacity: .6;
}

.bg-light-grad {
  background-image: linear-gradient(264.21deg, #a9d9f0 34.87%, #aabfd8 99.64%);
}

.bg-dark-grad {
  background-image: linear-gradient(88.58deg, #03045e 0%, #080bf7 100%);
}

.bg-services {
  background-image: linear-gradient(0deg, #0c3cbab3, #0c3cbab3), url("img-services-bg.ccef9820.webp");
}

.bg-dispo {
  background-image: linear-gradient(0deg, #0c3cbab3, #0c3cbab3), url("img-dispo-bg.df028ecb.webp");
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.p-2 {
  padding: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pb-px {
  padding-bottom: 1px;
}

.pr-4 {
  padding-right: 1rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-20 {
  padding-right: 5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pt-16 {
  padding-top: 4rem;
}

.text-center {
  text-align: center;
}

.font-secondary {
  font-family: Roboto, sans-serif;
}

.font-primary {
  font-family: Lato, sans-serif;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.leading-relaxed {
  line-height: 1.625;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-widest {
  letter-spacing: .1em;
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-slate-300 {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.text-accent {
  --tw-text-opacity: 1;
  color: rgb(255 189 0 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(3 4 94 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(26 86 219 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(224 36 36 / var(--tw-text-opacity));
}

.text-opacity-80 {
  --tw-text-opacity: .8;
}

.underline {
  text-decoration-line: underline;
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-red-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(224 36 36 / var(--tw-ring-opacity));
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-150 {
  transition-duration: .15s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

h1 {
  --tw-text-opacity: 1;
  color: rgb(3 4 94 / var(--tw-text-opacity));
  letter-spacing: .05em;
  font-family: Lato, sans-serif;
  font-weight: 900;
}

p {
  --tw-text-opacity: 1;
  color: rgb(85 96 155 / var(--tw-text-opacity));
  font-family: Roboto, sans-serif;
}

input {
  background-color: #d9d9d9;
  border-radius: .25rem;
}

.btn {
  --tw-text-opacity: 1;
  color: rgb(3 4 94 / var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 189 0 / var(--tw-bg-opacity));
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .25rem;
  padding: 1.25rem 2rem;
  font-family: Lato, sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 2rem;
  display: block;
}

.btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 208 76 / var(--tw-bg-opacity));
}

.btn-contact {
  padding: 18px 92px 18px 49px;
  position: relative;
}

.btn-contact:after {
  content: url("arrow-circle-right.f8c3f414.svg");
  position: absolute;
  right: 35px;
}

.header-accent {
  position: relative;
}

.header-accent:before {
  content: url("grib-mobile.e1349a13.svg");
  z-index: -1;
  position: absolute;
  top: -5px;
  right: -65px;
}

@media (min-width: 768px) {
  .header-accent:before {
    content: url("grib-tablet.09ad498b.svg");
    top: -10px;
    right: -100px;
  }
}

.title-accent {
  position: relative;
}

.title-accent:after {
  content: url("Frame.882462c0.svg");
  z-index: -1;
  position: absolute;
  bottom: -32px;
  right: -64px;
}

@media (min-width: 768px) {
  .form-control:after {
    top: 135px;
    right: 52px;
  }

  .form-control:before {
    top: 20px;
    right: 52px;
  }
}

.adress-input:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 189 0 / var(--tw-ring-opacity));
  outline: 2px solid #0000;
}

.dotes-container > * {
  cursor: pointer;
  width: 12px;
  aspect-ratio: 1;
  --tw-border-opacity: 1;
  border: 1px solid;
  border-color: rgb(3 4 94 / var(--tw-border-opacity));
  border-radius: 50%;
}

.dotes-container .tns-nav-active {
  --tw-bg-opacity: 1;
  background-color: rgb(3 4 94 / var(--tw-bg-opacity));
}

@media (min-width: 1024px) {
  .feature-2 {
    margin-top: -28px !important;
  }
}

.disable-select {
  user-select: none;
  -khtml-user-select: none;
}

.fade {
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: 1s;
  transition-timing-function: linear;
}

.fade-right {
  transform: translateX(50px);
}

.fade-left {
  transform: translateX(-50px);
}

.fade-up {
  transform: translateY(50px);
}

.in-view, .in-view.fade-up, .in-view .fade-up {
  opacity: 1;
  transform: none;
}

html {
  scroll-behavior: smooth;
}

.lds-ellipsis, .contact-lds-ellipsis {
  width: 80px;
  height: 30px;
  display: none;
  position: relative;
}

.lds-ellipsis div, .contact-lds-ellipsis div {
  width: 13px;
  height: 13px;
  background: #fff;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  position: absolute;
  top: 15px;
}

.lds-ellipsis div:nth-child(1), .contact-lds-ellipsis div:nth-child(1) {
  animation: lds-ellipsis1 .6s infinite;
  left: 8px;
}

.lds-ellipsis div:nth-child(2), .contact-lds-ellipsis div:nth-child(2) {
  animation: lds-ellipsis2 .6s infinite;
  left: 8px;
}

.lds-ellipsis div:nth-child(3), .contact-lds-ellipsis div:nth-child(3) {
  animation: lds-ellipsis2 .6s infinite;
  left: 32px;
}

.lds-ellipsis div:nth-child(4), .contact-lds-ellipsis div:nth-child(4) {
  animation: lds-ellipsis3 .6s infinite;
  left: 56px;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(24px);
  }
}

.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;
}

.success-checkmark .check-icon {
  width: 80px;
  height: 80px;
  box-sizing: content-box;
  border: 4px solid #4caf50;
  border-radius: 50%;
  position: relative;
}

.success-checkmark .check-icon:before {
  width: 30px;
  transform-origin: 100%;
  border-radius: 100px 0 0 100px;
  top: 3px;
  left: -2px;
}

.success-checkmark .check-icon:after {
  width: 60px;
  transform-origin: 0;
  border-radius: 0 100px 100px 0;
  animation: rotate-circle 4.25s ease-in;
  top: 0;
  left: 30px;
}

.success-checkmark .check-icon:before, .success-checkmark .check-icon:after {
  content: "";
  height: 100px;
  background: #fff;
  position: absolute;
  transform: rotate(-45deg);
}

.success-checkmark .check-icon .icon-line {
  height: 5px;
  z-index: 10;
  background-color: #4caf50;
  border-radius: 2px;
  display: block;
  position: absolute;
}

.success-checkmark .check-icon .icon-line.line-tip {
  width: 25px;
  animation: icon-line-tip .75s;
  top: 46px;
  left: 14px;
  transform: rotate(45deg);
}

.success-checkmark .check-icon .icon-line.line-long {
  width: 47px;
  animation: icon-line-long .75s;
  top: 38px;
  right: 8px;
  transform: rotate(-45deg);
}

.success-checkmark .check-icon .icon-circle {
  z-index: 10;
  width: 80px;
  height: 80px;
  box-sizing: content-box;
  border: 4px solid #4caf5080;
  border-radius: 50%;
  position: absolute;
  top: -4px;
  left: -4px;
}

.success-checkmark .check-icon .icon-fix {
  width: 5px;
  z-index: 1;
  height: 85px;
  background-color: #fff;
  position: absolute;
  top: 8px;
  left: 26px;
  transform: rotate(-45deg);
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    top: 19px;
    left: 1px;
  }

  54% {
    width: 0;
    top: 19px;
    left: 1px;
  }

  70% {
    width: 50px;
    top: 37px;
    left: -8px;
  }

  84% {
    width: 17px;
    top: 48px;
    left: 21px;
  }

  100% {
    width: 25px;
    top: 45px;
    left: 14px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    top: 54px;
    right: 46px;
  }

  65% {
    width: 0;
    top: 54px;
    right: 46px;
  }

  84% {
    width: 55px;
    top: 35px;
    right: 0;
  }

  100% {
    width: 47px;
    top: 38px;
    right: 8px;
  }
}

.sa {
  width: 140px;
  height: 140px;
  background-color: #fff;
  padding: 26px;
}

.sa-error {
  box-sizing: content-box;
  height: 80px;
  width: 80px;
  background-color: #fff;
  border: 4px solid #f27474;
  border-radius: 50%;
  padding: 0;
  animation: animateErrorIcon .5s;
  position: relative;
}

.sa-error:after, .sa-error:before {
  content: "";
  height: 120px;
  width: 60px;
  background: #fff;
  position: absolute;
  transform: rotate(45deg);
}

.sa-error:before {
  width: 26px;
  height: 80px;
  transform-origin: 60px 60px;
  border-radius: 40px 0 0 40px;
  top: -17px;
  left: 5px;
  transform: rotate(-45deg);
}

.sa-error:after {
  transform-origin: 0 60px;
  border-radius: 0 120px 120px 0;
  animation: rotatePlaceholder 4.25s ease-in;
  top: -11px;
  left: 30px;
  transform: rotate(-45deg);
}

.sa-error-x {
  z-index: 2;
  display: block;
  position: relative;
}

.sa-error-placeholder {
  box-sizing: content-box;
  height: 80px;
  width: 80px;
  z-index: 2;
  border: 4px solid #c8000033;
  border-radius: 50%;
  position: absolute;
  top: -4px;
  left: -4px;
}

.sa-error-fix {
  height: 90px;
  width: 5px;
  z-index: 1;
  background-color: #fff;
  position: absolute;
  top: 8px;
  left: 28px;
  transform: rotate(-45deg);
}

.sa-error-left, .sa-error-right {
  height: 5px;
  z-index: 2;
  width: 47px;
  background-color: #f27474;
  border-radius: 2px;
  display: block;
  position: absolute;
  top: 37px;
}

.sa-error-left {
  animation: animateXLeft .75s;
  left: 17px;
  transform: rotate(45deg);
}

.sa-error-right {
  animation: animateXRight .75s;
  right: 16px;
  transform: rotate(-45deg);
}

@keyframes rotatePlaceholder {
  0%, 5% {
    transform: rotate(-45deg);
  }

  100%, 12% {
    transform: rotate(-405deg);
  }
}

@keyframes animateErrorIcon {
  0% {
    opacity: 0;
    transform: rotateX(100deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

@keyframes animateXLeft {
  0%, 65% {
    width: 0;
    top: 95px;
    left: 82px;
  }

  84% {
    width: 47px;
    top: 33px;
    left: 14px;
  }

  100% {
    width: 47px;
    top: 37px;
    left: 17px;
  }
}

@keyframes animateXRight {
  0%, 65% {
    width: 0;
    top: 95px;
    right: 82px;
  }

  84% {
    width: 47px;
    top: 33px;
    right: 14px;
  }

  100% {
    width: 47px;
    top: 37px;
    right: 16px;
  }
}

.autoComplete_wrapper {
  position: relative;
}

.autoComplete_wrapper > input::placeholder {
  transition: all .3s;
}

.autoComplete_wrapper > ul {
  max-height: 226px;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #21212112;
  outline: none;
  margin: .5rem 0 0;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow-y: scroll;
  box-shadow: 0 3px 6px #959da526;
}

.autoComplete_wrapper > ul[hidden], .autoComplete_wrapper > ul:empty {
  opacity: 0;
  display: block;
  transform: scale(0);
}

.autoComplete_wrapper > ul > li {
  text-align: left;
  color: #212121;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #fff;
  border-radius: .35rem;
  margin: .3rem;
  padding: .3rem .5rem;
  font-size: 1rem;
  list-style: none;
  transition: all .2s;
  overflow: hidden;
}

.autoComplete_wrapper > ul > li::selection {
  color: rgba(#fff, 0);
  background-color: rgba(#fff, 0);
}

.autoComplete_wrapper > ul > li:hover {
  cursor: pointer;
  background-color: #ffda73;
}

.autoComplete_wrapper > ul > li mark {
  color: #040688;
  background-color: #0000;
  font-weight: bold;
}

.autoComplete_wrapper > ul > li mark::selection {
  color: rgba(#fff, 0);
  background-color: rgba(#fff, 0);
}

.autoComplete_wrapper > ul > li[aria-selected="true"] {
  background-color: #7b7b7b1a;
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:top-3:after {
  content: var(--tw-content);
  top: .75rem;
}

.after\:right-7:after {
  content: var(--tw-content);
  right: 1.75rem;
}

.after\:content-iconMarker:after {
  --tw-content: url("icon-input-marker.b7b7c95a.svg");
  content: var(--tw-content);
}

.hover\:border-primary:hover {
  --tw-border-opacity: 1;
  border-color: rgb(3 4 94 / var(--tw-border-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-slate-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(88 80 236 / var(--tw-bg-opacity));
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(3 4 94 / var(--tw-text-opacity));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.focus\:border:focus {
  border-width: 1px;
}

.focus\:border-primary:focus {
  --tw-border-opacity: 1;
  border-color: rgb(3 4 94 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-gray-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity));
}

.focus\:ring-primary:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(3 4 94 / var(--tw-ring-opacity));
}

.focus\:ring-gray-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.dark .dark\:text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:h-9 {
    height: 2.25rem;
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:gap-6 {
    gap: 1.5rem;
  }

  .sm\:text-center {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-20 {
    height: 5rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-2\/3 {
    width: 66.6667%;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)) );
  }

  .md\:border-0 {
    border-width: 0;
  }

  .md\:bg-transparent {
    background-color: #0000;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:pl-14 {
    padding-left: 3.5rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:font-medium {
    font-weight: 500;
  }

  .md\:leading-snug {
    line-height: 1.375;
  }

  .md\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgb(26 86 219 / var(--tw-text-opacity));
  }

  .md\:after\:top-5:after {
    content: var(--tw-content);
    top: 1.25rem;
  }

  .md\:after\:right-5:after {
    content: var(--tw-content);
    right: 1.25rem;
  }

  .md\:hover\:bg-transparent:hover {
    background-color: #0000;
  }

  .md\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgb(26 86 219 / var(--tw-text-opacity));
  }
}

@media (min-width: 1024px) {
  .lg\:-right-60 {
    right: -15rem;
  }

  .lg\:-right-48 {
    right: -12rem;
  }

  .lg\:m-0 {
    margin: 0;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:mb-64 {
    margin-bottom: 16rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-full {
    height: 100%;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)) );
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .lg\:overflow-x-visible {
    overflow-x: visible;
  }

  .lg\:border-r-2 {
    border-right-width: 2px;
  }

  .lg\:border-b-2 {
    border-bottom-width: 2px;
  }

  .lg\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:pl-6 {
    padding-left: 1.5rem;
  }

  .lg\:pr-16 {
    padding-right: 4rem;
  }

  .lg\:pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:pt-20 {
    padding-top: 5rem;
  }

  .lg\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1280px) {
  .xl\:max-w-2xl {
    max-width: 42rem;
  }

  .xl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:-right-4 {
    right: -1rem;
  }
}

/*# sourceMappingURL=index.d91f2a1c.css.map */
