@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  @apply text-primary;
  @apply font-black;
  @apply font-primary;
  @apply tracking-wider;
}
p {
  @apply text-neutral;
  @apply font-secondary;
}
input {
  @apply rounded;
  background-color: #d9d9d9;
}

.btn {
  @apply block;
  @apply text-primary;
  @apply font-primary;
  @apply text-2xl;
  @apply font-black;
  @apply bg-accent;
  @apply px-8;
  @apply py-5;
  @apply rounded;
  @apply shadow-md;
  @apply hover:bg-btn-hover;
}

.btn-contact {
  padding: 18px 92px 18px 49px;
  position: relative;
}

.btn-contact::after {
  content: url("/src/images/arrow-circle-right.svg");
  position: absolute;
  right: 35px;
}

.header-accent {
  position: relative;
}

.header-accent::before {
  content: url("/src/images/grib-mobile.svg");
  position: absolute;
  right: -65px;
  top: -5px;
  z-index: -1;
}

@media (min-width: 768px) {
  .header-accent::before {
    content: url("/src/images/grib-tablet.svg");
    right: -100px;
    top: -10px;
  }
}

.title-accent {
  position: relative;
}

.title-accent::after {
  content: url("/src/images/Frame.svg");
  position: absolute;
  bottom: -32px;
  right: -64px;
  z-index: -1;
}

@media (min-width: 768px) {
  .form-control::after {
    right: 52px;
    top: 135px;
  }

  .form-control::before {
    right: 52px;
    top: 20px;
  }
}

.adress-input:focus {
  @apply outline-none;
  @apply ring-4;
  @apply ring-accent;
}

.dotes-container > * {
  cursor: pointer;
  width: 12px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 1px solid;
  @apply border-primary;
}

.dotes-container .tns-nav-active {
  @apply bg-primary;
}

@media (min-width: 1024px) {
  .feature-2 {
    margin-top: -28px !important;
  }
}
.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.fade {
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: 1s;
  transition-timing-function: linear;
}

.fade-right {
  transform: translateX(50px);
}

.fade-left {
  transform: translateX(-50px);
}

.fade-up {
  transform: translateY(50px);
}

.in-view {
  transform: none;
  opacity: 1;
}

.in-view.fade-up,
.in-view .fade-up {
  transform: none;
  opacity: 1;
}

html {
  scroll-behavior: smooth;
}

/*Loading animation */
.lds-ellipsis,
.contact-lds-ellipsis {
  display: none;
  position: relative;
  width: 80px;
  height: 30px;
}
.lds-ellipsis div,
.contact-lds-ellipsis div {
  position: absolute;
  top: 15px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1),
.contact-lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2),
.contact-lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3),
.contact-lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4),
.contact-lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/*success animation */
.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;
}
.success-checkmark .check-icon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid #4caf50;
}
.success-checkmark .check-icon::before {
  top: 3px;
  left: -2px;
  width: 30px;
  transform-origin: 100% 50%;
  border-radius: 100px 0 0 100px;
}
.success-checkmark .check-icon::after {
  top: 0;
  left: 30px;
  width: 60px;
  transform-origin: 0 50%;
  border-radius: 0 100px 100px 0;
  animation: rotate-circle 4.25s ease-in;
}
.success-checkmark .check-icon::before,
.success-checkmark .check-icon::after {
  content: "";
  height: 100px;
  position: absolute;
  background: #fff;
  transform: rotate(-45deg);
}
.success-checkmark .check-icon .icon-line {
  height: 5px;
  background-color: #4caf50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}
.success-checkmark .check-icon .icon-line.line-tip {
  top: 46px;
  left: 14px;
  width: 25px;
  transform: rotate(45deg);
  animation: icon-line-tip 0.75s;
}
.success-checkmark .check-icon .icon-line.line-long {
  top: 38px;
  right: 8px;
  width: 47px;
  transform: rotate(-45deg);
  animation: icon-line-long 0.75s;
}
.success-checkmark .check-icon .icon-circle {
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 4px solid rgba(76, 175, 80, 0.5);
}
.success-checkmark .check-icon .icon-fix {
  top: 8px;
  width: 5px;
  left: 26px;
  z-index: 1;
  height: 85px;
  position: absolute;
  transform: rotate(-45deg);
  background-color: #fff;
}
@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}
@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

/*Error Animation*/
.sa {
  width: 140px;
  height: 140px;
  padding: 26px;
  background-color: #fff;
}
.sa-error {
  border-radius: 50%;
  border: 4px solid #f27474;
  box-sizing: content-box;
  height: 80px;
  padding: 0;
  position: relative;
  background-color: #fff;
  width: 80px;
  animation: animateErrorIcon 0.5s;
}
.sa-error:after,
.sa-error:before {
  background: #fff;
  content: "";
  height: 120px;
  position: absolute;
  transform: rotate(45deg);
  width: 60px;
}
.sa-error:before {
  border-radius: 40px 0 0 40px;
  width: 26px;
  height: 80px;
  top: -17px;
  left: 5px;
  transform-origin: 60px 60px;
  transform: rotate(-45deg);
}
.sa-error:after {
  border-radius: 0 120px 120px 0;
  left: 30px;
  top: -11px;
  transform-origin: 0 60px;
  transform: rotate(-45deg);
  animation: rotatePlaceholder 4.25s ease-in;
}
.sa-error-x {
  display: block;
  position: relative;
  z-index: 2;
}
.sa-error-placeholder {
  border-radius: 50%;
  border: 4px solid rgba(200, 0, 0, 0.2);
  box-sizing: content-box;
  height: 80px;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 80px;
  z-index: 2;
}
.sa-error-fix {
  background-color: #fff;
  height: 90px;
  left: 28px;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 5px;
  z-index: 1;
}
.sa-error-left,
.sa-error-right {
  border-radius: 2px;
  display: block;
  height: 5px;
  position: absolute;
  z-index: 2;
  background-color: #f27474;
  top: 37px;
  width: 47px;
}
.sa-error-left {
  left: 17px;
  transform: rotate(45deg);
  animation: animateXLeft 0.75s;
}
.sa-error-right {
  right: 16px;
  transform: rotate(-45deg);
  animation: animateXRight 0.75s;
}

@keyframes rotatePlaceholder {
  0%,
  5% {
    transform: rotate(-45deg);
  }
  100%,
  12% {
    transform: rotate(-405deg);
  }
}
@keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}
@keyframes animateXLeft {
  0%,
  65% {
    left: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    left: 14px;
    top: 33px;
    width: 47px;
  }
  100% {
    left: 17px;
    top: 37px;
    width: 47px;
  }
}
@keyframes animateXRight {
  0%,
  65% {
    right: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    right: 14px;
    top: 33px;
    width: 47px;
  }
  100% {
    right: 16px;
    top: 37px;
    width: 47px;
  }
}

/* autoComplete ************************************/

.autoComplete_wrapper {
  position: relative;
}

.autoComplete_wrapper > input::placeholder {
  transition: all 0.3s ease;
}

.autoComplete_wrapper > ul {
  position: absolute;
  max-height: 226px;
  overflow-y: scroll;
  top: 100%;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0.5rem 0 0 0;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(149, 157, 165, 0.15);
  border: 1px solid rgba(33, 33, 33, 0.07);
  z-index: 1000;
  outline: none;
}

.autoComplete_wrapper > ul[hidden],
.autoComplete_wrapper > ul:empty {
  display: block;
  opacity: 0;
  transform: scale(0);
}

.autoComplete_wrapper > ul > li {
  margin: 0.3rem;
  padding: 0.3rem 0.5rem;
  list-style: none;
  text-align: left;
  font-size: 1rem;
  color: #212121;
  transition: all 0.1s ease-in-out;
  border-radius: 0.35rem;
  background-color: rgba(255, 255, 255, 1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s ease;
}

.autoComplete_wrapper > ul > li::selection {
  color: rgba(#ffffff, 0);
  background-color: rgba(#ffffff, 0);
}

.autoComplete_wrapper > ul > li:hover {
  cursor: pointer;
  background-color: #ffda73;
}

.autoComplete_wrapper > ul > li mark {
  background-color: transparent;
  color: #040688;
  font-weight: bold;
}

.autoComplete_wrapper > ul > li mark::selection {
  color: rgba(#ffffff, 0);
  background-color: rgba(#ffffff, 0);
}

.autoComplete_wrapper > ul > li[aria-selected="true"] {
  background-color: rgba(123, 123, 123, 0.1);
}
